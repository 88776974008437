import _LazyBrush3 from "./LazyBrush";
import _Point3 from "./Point";
import _LazyPoint3 from "./LazyPoint";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LazyPoint = exports.Point = exports.LazyBrush = undefined;
var _LazyBrush = _LazyBrush3;

var _LazyBrush2 = _interopRequireDefault(_LazyBrush);

var _Point = _Point3;

var _Point2 = _interopRequireDefault(_Point);

var _LazyPoint = _LazyPoint3;

var _LazyPoint2 = _interopRequireDefault(_LazyPoint);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.LazyBrush = _LazyBrush2.default;
exports.Point = _Point2.default;
exports.LazyPoint = _LazyPoint2.default;
export default exports;
export const __esModule = exports.__esModule,
      LazyPoint = exports.LazyPoint,
      Point = exports.Point,
      LazyBrush = exports.LazyBrush;