import _LazyPoint3 from "./LazyPoint";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _LazyPoint = _LazyPoint3;

var _LazyPoint2 = _interopRequireDefault(_LazyPoint);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var RADIUS_DEFAULT = 30;

var LazyBrush = function () {
  /**
   * constructor
   *
   * @param {object} settings
   * @param {number} settings.radius The radius for the lazy area
   * @param {boolean} settings.enabled
   */
  function LazyBrush() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$radius = _ref.radius,
        radius = _ref$radius === undefined ? RADIUS_DEFAULT : _ref$radius,
        _ref$enabled = _ref.enabled,
        enabled = _ref$enabled === undefined ? true : _ref$enabled,
        _ref$initialPoint = _ref.initialPoint,
        initialPoint = _ref$initialPoint === undefined ? {
      x: 0,
      y: 0
    } : _ref$initialPoint;

    _classCallCheck(this, LazyBrush);

    this.radius = radius;
    this._isEnabled = enabled;
    this.pointer = new _LazyPoint2.default(initialPoint.x, initialPoint.y);
    this.brush = new _LazyPoint2.default(initialPoint.x, initialPoint.y);
    this.angle = 0;
    this.distance = 0;
    this._hasMoved = false;
  }
  /**
   * Enable lazy brush calculations.
   *
   */


  _createClass(LazyBrush, [{
    key: "enable",
    value: function enable() {
      this._isEnabled = true;
    }
    /**
     * Disable lazy brush calculations.
     *
     */

  }, {
    key: "disable",
    value: function disable() {
      this._isEnabled = false;
    }
    /**
     * @returns {boolean}
     */

  }, {
    key: "isEnabled",
    value: function isEnabled() {
      return this._isEnabled;
    }
    /**
     * Update the radius
     *
     * @param {number} radius
     */

  }, {
    key: "setRadius",
    value: function setRadius(radius) {
      this.radius = radius;
    }
    /**
     * Return the current radius
     *
     * @returns {number}
     */

  }, {
    key: "getRadius",
    value: function getRadius() {
      return this.radius;
    }
    /**
     * Return the brush coordinates as a simple object
     *
     * @returns {object}
     */

  }, {
    key: "getBrushCoordinates",
    value: function getBrushCoordinates() {
      return this.brush.toObject();
    }
    /**
     * Return the pointer coordinates as a simple object
     *
     * @returns {object}
     */

  }, {
    key: "getPointerCoordinates",
    value: function getPointerCoordinates() {
      return this.pointer.toObject();
    }
    /**
     * Return the brush as a LazyPoint
     *
     * @returns {LazyPoint}
     */

  }, {
    key: "getBrush",
    value: function getBrush() {
      return this.brush;
    }
    /**
     * Return the pointer as a LazyPoint
     *
     * @returns {LazyPoint}
     */

  }, {
    key: "getPointer",
    value: function getPointer() {
      return this.pointer;
    }
    /**
     * Return the angle between pointer and brush
     *
     * @returns {number} Angle in radians
     */

  }, {
    key: "getAngle",
    value: function getAngle() {
      return this.angle;
    }
    /**
     * Return the distance between pointer and brush
     *
     * @returns {number} Distance in pixels
     */

  }, {
    key: "getDistance",
    value: function getDistance() {
      return this.distance;
    }
    /**
     * Return if the previous update has moved the brush.
     *
     * @returns {boolean} Whether the brush moved previously.
     */

  }, {
    key: "brushHasMoved",
    value: function brushHasMoved() {
      return this._hasMoved;
    }
    /**
     * Updates the pointer point and calculates the new brush point.
     *
     * @param {Point} newPointerPoint
     * @param {Object} options
     * @param {Boolean} options.both Force update pointer and brush
     * @returns {Boolean} Whether any of the two points changed
     */

  }, {
    key: "update",
    value: function update(newPointerPoint) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref2$both = _ref2.both,
          both = _ref2$both === undefined ? false : _ref2$both;

      this._hasMoved = false;

      if (this.pointer.equalsTo(newPointerPoint) && !both) {
        return false;
      }

      this.pointer.update(newPointerPoint);

      if (both) {
        this._hasMoved = true;
        this.brush.update(newPointerPoint);
        return true;
      }

      if (this._isEnabled) {
        this.distance = this.pointer.getDistanceTo(this.brush);
        this.angle = this.pointer.getAngleTo(this.brush);

        if (this.distance > this.radius) {
          this.brush.moveByAngle(this.angle, this.distance - this.radius);
          this._hasMoved = true;
        }
      } else {
        this.distance = 0;
        this.angle = 0;
        this.brush.update(newPointerPoint);
        this._hasMoved = true;
      }

      return true;
    }
  }]);

  return LazyBrush;
}();

exports.default = LazyBrush;
export default exports;